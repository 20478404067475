
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font-awesome
@import "node_modules/font-awesome/css/font-awesome";

// Font-awesome
@import "pnotify.custom.min.css";

html,body
{
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
    background: #ffffff;
}

.navbar-default{
	background-color: rgba(0,0,0,0.3);
}
.navbar{
	font-family: $font-family-sans-serif;
	padding-top: 30px;
	color: #fff;
	z-index: 6;
	min-height: 120px;
	border: none;
}
.navbar-default .navbar-brand {
	-webkit-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	-moz-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	color: #fff;
}
.navbar-default .navbar-nav>li>a {
	-webkit-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	-moz-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	color: #fff;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
	color: $main-red1;
	background: none;
}

.navbar-default .navbar-brand:hover{
	color: $main-orange1;
}
.navbar-default .navbar-nav>li>a:hover{
	color: $main-orange1;
}


#header{
	position: relative;
	min-height: 800px;
	width: 100%;
}
#hero{
	font-family: 'Raleway', sans-serif;
	position: absolute;
	top: 0;
	color: #fff;
	text-align: center;
	width: 100%;
	height: -webkit-calc(100% - 10rem);
	height: calc(100%);
}

#hero .background{
	width: 100%;
	height: 100%;
	// background: url(../imagens/proteccao-juridica-top-background.jpg);
	background-position: center;
	background-size: cover;
}
#hero .image-overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(38,51,60,0.95);
	background-image: -webkit-linear-gradient(top, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-image: linear-gradient(to bottom, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-color: transparent;
	z-index: 1;
}

#hero .text-wrapper{
	position: absolute;
	width: 100%;
	bottom: 0;
	right: 0;
	left: 0;
	top: 19em;
	margin: 0 auto;
	text-shadow: 0 0.0625rem 0.125rem rgba(0,0,0,0.2);
	z-index: 4;
}
#hero .text-wrapper .barra{
	display: block;
	height: 12px;
	background: #fff;
	width: 42px;
	margin: 0 auto;
	margin-top: 2em;
}

#hero h1{
	font-size: 6em;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
}
#hero h2{
	margin-top: 2em;
	font-size: 1.8em;
	font-weight: 300;
	text-transform: uppercase;
	color: #fff;
}

#hero .botao{
	margin-top: 2em;
	-webkit-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	-moz-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	position: relative;
	display: inline-block;
	cursor: pointer;
	border-radius: 0.25rem;
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	border: 0.125rem solid transparent;
	letter-spacing: .5px;
	color: rgba(255,255,255,0.9);
	background-color: $main-orange1;
	box-shadow: 0 0.125rem 0 $main-orange1;
	font-size: 1.6em;
	padding: 0.6875em 1.375em;
}

#hero .botao:hover{
	background-color: $main-red1;
	box-shadow: 0 0.125rem 0 $main-red1;
}



@media (min-width: 768px){
	.navbar {
		border-radius: 0px;
	}
}

#servicos{
	display: block;
	position: relative;
	width: 100%;
	min-height: 400px;
	padding-top:120px;
	padding-bottom: 120px;
	color: $main-orange1;
	text-align: center;
}

#servicos .box{
	width: 200px;
	height: 200px;
	margin: 0 auto;
	border:2px solid $main-orange1;
}

#servicos h3{
	font-family: 'Raleway', sans-serif;
	font-size: 1.2em;
	font-weight: 400;
	color: #473E3F;
}

#servicos .box i{
	position: absolute;
	font-size: 3em;
	top: 50%;
	left: 50%;
	margin-right: -50%;
	transform: translate(-50%, -50%);
}

#servicos a.box{
	color:  $main-orange1;
}

#servicos a.box:hover{
	color: #fff;
}

.hvr-rectangle-out {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	background: #fff;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}
.hvr-rectangle-out:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $main-red1;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
	color: white;
}
.hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.hvr-bounce-to-left {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	-webkit-transition-property: color;
	transition-property: color;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.hvr-bounce-to-left:before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(71,62,63,0.21);
	background: -moz-linear-gradient(left, rgba(71,62,63,0.21) 0%, rgba(117,189,209,0.05) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(71,62,63,0.21)), color-stop(100%, rgba(117,189,209,0.05)));
	background: -webkit-linear-gradient(left, rgba(71,62,63,0.21) 0%, rgba(117,189,209,0.05) 100%);
	background: -o-linear-gradient(left, rgba(71,62,63,0.21) 0%, rgba(117,189,209,0.05) 100%);
	background: -ms-linear-gradient(left, rgba(71,62,63,0.21) 0%, rgba(117,189,209,0.05) 100%);
	background: linear-gradient(to right, rgba(71,62,63,0.21) 0%, rgba(117,189,209,0.05) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#473e3f', endColorstr='#75bdd1', GradientType=1 );
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.5s;
	transition-duration: 0.5s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
.hvr-bounce-to-left:hover, .hvr-bounce-to-left:focus, .hvr-bounce-to-left:active {
	color: white;
}
.hvr-bounce-to-left:hover:before, .hvr-bounce-to-left:focus:before, .hvr-bounce-to-left:active:before {
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
	transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}


.seguro-vida{
	width: 100%;
	height: 800px;
	padding-top: 20px;
	padding-right: 20px;
	padding-left: 20px;
}
.seguro .background{
	width: 100%;
	height: 800px;
	background-position: left;
	background-size: cover;
}

.seguro .left{
	width: 79%;
	display: inline-block;
	position: relative;
	height: 100%;
}

.seguro .right{
	position: relative;
	display: inline-block;
	width: 20%;
	height: 100%;
	float: right;
	bottom:0;
}

.seguro .right a{
	display: table;
	font-family: 'Raleway', sans-serif;
	width: 100%;
	height: 100%;
	color: #fff;
	position: relative;
	-webkit-transition: color 0.2s ease, background-color 1s ease, border-color 0.2s ease;
	-moz-transition: color 0.2s ease, background-color 1s ease, border-color 0.2s ease;
	transition: color 0.2s ease, background-color 1s ease, border-color 0.2s ease;
}

.seguro .right a:hover{
	color: #fff;
	text-decoration: none;

}

.seguro .right a span{
	display: table-cell;
	vertical-align: middle;
	font-size: 20em;
	font-weight: 100;
	margin: 0 auto;
	font-size-adjust: initial;
	text-rendering: optimizeSpeed;
}

.seguro .container h2{
	font-family: 'Raleway', sans-serif;
	padding-top: 2em;
	font-size: 4em;
	font-weight: 800;
	color: #fff;
}
.seguro span.barra{
	display: block;
	height: 8px;
	width: 35px;
	background: #fff;
}

.seguro .container .text{
	width: 75%;
	font-family: 'Raleway', sans-serif;
	padding-top: 2em;
	font-weight: 400;
	font-size: 1.8em;
	color: #fff
}

.preco{
	font-size: 2em;
	font-weight: 300;
}
.mes{
	font-size: 1em;
	text-transform: uppercase;
	font-weight: 600;
}

#contact{
	font-family: 'Raleway', sans-serif;
	min-height: 400px;
	padding-top: 80px;
	padding-bottom: 80px;
}
#contact h3{
	color: #473E3F;
	padding-bottom: 20px;
}

#contact .my-form{
	color: #473E3F;
	display: block;
	width: 100%;
	height: 40px;
	border:none;
	border-bottom: 2px solid $main-red2;
}

#contact .msg{
	padding-top: 20px;
}

#contact .my-form:focus{
	border-color: $main-red2;
	outline: 0;
	border-bottom: 4px solid $main-red2;
}

#contact .my-mensagem{
	display: block;
	width: 100%;
	height: 100px;
	border:none;
	border-bottom: 2px solid $main-red2;
}

#contact .my-mensagem:focus{
	border-color: $main-red2;
	outline: 0;
	border-bottom: 4px solid $main-red2;
}
#contact .form-group label{
	color: #473E3F;
}

#footer{
	height: 100px;
	background: #fff;
	background: #473E3F;
}

#footer p{
	padding-top: 40px;
	color: #fff;
}


#headerPage{
	position: relative;
	min-height: 800px;
	width: 100%;
}
.seguro-page{
	font-family: 'Raleway', sans-serif;
	position: absolute;
	top: 0;
	color: #fff;
	text-align: center;
	width: 100%;
	height: -webkit-calc(100% - 10rem);
	height: calc(100%);
}

.seguro-page .background{
	width: 100%;
	height: 100%;
	// background: url(../imagens/seguro-vida.jpg);
	background-position: center;
	background-size: cover;
}
.seguro-page .image-overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(38,51,60,0.95);
	background-image: -webkit-linear-gradient(top, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-image: linear-gradient(to bottom, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-color: transparent;
	z-index: 1;
}

.seguro-page .text-wrapper{
	position: absolute;
	width: 100%;
	bottom: 0;
	right: 0;
	left: 0;
	top: 15em;
	margin: 0 auto;
	text-shadow: 0 0.0625rem 0.125rem rgba(0,0,0,0.2);
	z-index: 4;
}
.seguro-page .text-wrapper .barra{

	display: block;
	height: 12px;
	background: #fff;
	width: 42px;
	margin: 0 auto;
	margin-top: 2em;
}

.seguro-page h1{
	font-size: 6em;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
}
.seguro-page h2{
	margin-top: 2em;
	font-size: 1.8em;
	font-weight: 300;
	text-transform: uppercase;
	color: #fff;
}

.seguro-page .botao{
	margin-top: 2em;
	-webkit-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	-moz-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
	position: relative;
	display: inline-block;
	cursor: pointer;
	border-radius: 0.25rem;
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	line-height: 1;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	border: 0.125rem solid transparent;
	letter-spacing: .5px;
	color: rgba(255,255,255,0.9);
	background-color: #EE483E;
	box-shadow: 0 0.125rem 0 $main-red1;
	background-color: $main-red1;
	box-shadow: 0 0.125rem 0 $main-red1;
	font-size: 1.6em;
	padding: 0.6875em 1.375em;
}
.seguro-page .botao:hover{
	background-color: $main-red2;
	box-shadow: 0 0.125rem 0 $main-red2;
	box-shadow: 0 0.125rem 0 $main-red2;
}

#about{
	display: block;
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
}
#about h3{
	font-size: 2em;
	text-transform: uppercase;
	font-weight: 800;
}
#about {
	p, li{
		font-size: 1.3em;
	}
	.inner{
		padding-top: 60px;
	}
}

#content{
	display: block;
	position: relative;
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
}
#content h3{
	font-size: 2em;
	text-transform: uppercase;
	font-weight: 800;
}
#content {
	p, li{
		font-size: 1.3em;
	}
	.inner{
		padding-top: 60px;
	}
	.servicos{
		height: 350px;
		display: block;
		position: relative;
		width: 100%;
	}
	.top-md{
		padding-top: 20px;
	}
		.servicos .box{
		width: 200px;
		height: 200px;
		margin: 0 auto;
		border:2px solid $main-red1;
		text-align: center;
	}
	.servicos h4{
		font-family: 'Raleway', sans-serif;
		font-size: 1.2em;
		font-weight: 400;
		color: #473E3F;
	}
	.servicos .box i{
		position: absolute;
		font-size: 3em;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
	}

	.servicos a.box{
		color:  $main-red1;
	}

	.servicos a.box:hover{
		color: #fff;
	}
	.botao{
		margin-top: 2em;
		-webkit-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
		-moz-transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
		transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
		position: relative;
		display: inline-block;
		cursor: pointer;
		border-radius: 0.25rem;
		-webkit-font-smoothing: antialiased;
		font-weight: 500;
		line-height: 1;
		text-decoration: none;
		text-transform: uppercase;
		user-select: none;
		vertical-align: middle;
		white-space: nowrap;
		border: 0.125rem solid transparent;
		letter-spacing: .5px;
		color: rgba(255,255,255,0.9);
		background-color: #EE483E;
		box-shadow: 0 0.125rem 0 $main-red1;
		background-color: $main-red1;
		box-shadow: 0 0.125rem 0 $main-red1;
		font-size: 1.6em;
		padding: 0.6875em 1.375em;
	}
	.botao:hover{
		background-color: $main-red2;
		box-shadow: 0 0.125rem 0 $main-red2;
		box-shadow: 0 0.125rem 0 $main-red2;
	}
	.pedir{
		width: 100%;
		text-align: center;
		padding-bottom: 100px;
	}
}





#simulacao-page{
	font-family: 'Raleway', sans-serif;
	position: absolute;
	top: 0;
	color: #fff;
	text-align: center;
	width: 100%;
	height: -webkit-calc(100% - 10rem);
	height: calc(100%);
}

#simulacao-page .background{
	width: 100%;
	height: 100%;
	// background: url(../imagens/simulacao.jpg);
	background-position: center;
	background-size: cover;
}
#simulacao-page .image-overlay{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(38,51,60,0.95);
	background-image: -webkit-linear-gradient(top, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-image: linear-gradient(to bottom, rgba(38,51,60,0.95) 0%, rgba(38,51,60,0.65) 20%, rgba(38,51,60,0.65) 30%, rgba(38,51,60,0.65) 100%);
	background-color: transparent;
	z-index: 1;
}

#simulacao-page .text-wrapper{
	position: absolute;
	width: 100%;
	bottom: 0;
	right: 0;
	left: 0;
	top: 15em;
	margin: 0 auto;
	text-shadow: 0 0.0625rem 0.125rem rgba(0,0,0,0.2);
	z-index: 4;
}
#simulacao-page .text-wrapper .barra{

	display: block;
	height: 12px;
	background: #fff;
	width: 42px;
	margin: 0 auto;
	margin-top: 2em;
}

#simulacao-page h1{
	font-size: 6em;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
}
#simulacao-page h2{
	margin-top: 2em;
	font-size: 1.8em;
	font-weight: 300;
	text-transform: uppercase;
	color: #fff;
}

#simulacao-page .botao:hover{
	background-color: $main-red2;
	box-shadow: 0 0.125rem 0 $main-red2;
	box-shadow: 0 0.125rem 0 $main-red2;
}

#content .my-form{
	color: #473E3F;
	display: block;
	width: 100%;
	height: 40px;
	border:none;
	border-bottom: 2px solid $main-red2;
}

#content .my-check{
	border:none;
	border-bottom: 2px solid $main-red2;
	height: 20px;
	width: 20px;
	color: #473E3F;
}

#content .msg{
	padding-top: 20px;
}

#content .my-form:focus{
	border-color: $main-red2;
	outline: 0;
	border-bottom: 4px solid $main-red2;
}

#content .my-mensagem{
	display: block;
	width: 100%;
	height: 100px;
	border:none;
	border-bottom: 2px solid $main-red2;
}

#content .my-mensagem:focus{
	border-color: $main-red2;
	outline: 0;
	border-bottom: 4px solid $main-red2;
}
#content .form-group label{
	color: #473E3F;
}

#content .inner-bottom{
	padding-bottom: 40px;
}

@media (max-width: $breackpoint-medium) {
	#hero h1, .seguro-page h1 {
		font-size: 3em;
	}
	.seguro .container h2 {
		font-size: 2em;
	}
	.seguro .container .text {
	    width: 100%;
	    padding-top: 2em;
	    font-weight: 400;
	    font-size: 1em;
	    color: #fff;
	}
	.seguro .left {
		display: block;
		width: 100%;
		height: auto;
	}
	.seguro .right {
	    position: relative;
	    display: block;
	    width: 100%;
	    height: 100%;
		height: auto;
		text-align: center;
	}
	.seguro .right a span {
		font-size: 10em;
	}
	.seguro .background {
    	width: 100%;
    	height: 600px;
	}
	#about h3, #content h3{
		font-size: 1.5em;
		text-transform: uppercase;
		font-weight: 800;
	}
	#about, #content {
		p, li{
			font-size: 1em;
		}
		.inner{
			padding-top: 40px;
		}
	}
	#headerPage {
 	   min-height: 500px;
	}
}
